import styled from 'styled-components';

interface ContainerProps {
  isMenuOpened: boolean;
}

export const Container = styled.nav<ContainerProps>`
  position: fixed;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: ${(props) => (props.isMenuOpened ? '240px' : '54px')};
  transition: 0.5s;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: var(--background-sidebar);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  span {
    display: ${(props) => (props.isMenuOpened ? 'block-inline' : 'none')};
  }

  & > ul > li > a > span + svg {
    display: ${(props) => (props.isMenuOpened ? 'unset' : 'none')};
  }
`;

export const MainMenu = styled.ul`
  list-style: none;
  padding: 54px 24px 24px 24px;
  z-index: 10;
  :hover {
    width: 240px;
    background-color: var(--background-sidebar);
    height: 100%;
  }
  :hover span {
    display: inline-block;
  }
  :hover > li > a > span + svg {
    display: unset;
  }

  a {
    text-decoration: none;
  }
`;
