import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { Can } from 'components';

import { Item, LabelMenu, SubMenu, IconArrowDown } from './styles';
import ItemSubMenu from '../ItemSubMenu';

interface Menu {
  name: string;
  route: string;
  icon?: ReactElement | null;
  children: Menu[];
  perm?: string;
  type: 'access' | 'dashboard';
}

interface Props {
  menu: Menu;
}

const ItemMenu: React.FC<Props> = ({ menu }) => {
  return (
    <>
      <Item key={menu.name}>
        <NavLink
          to={menu.route}
          activeClassName={menu.children.length > 0 ? '' : 'sideBarLinkActive'}
        >
          {menu.icon}
          <LabelMenu>{menu.name}</LabelMenu>
          {menu.children.length > 0 && <IconArrowDown />}
        </NavLink>
        {menu.children.length > 0 && (
          <SubMenu className="submenu">
            {menu.children.map((child: Menu) => {
              return child.type === 'access' ? (
                <Can key={child.name} checkAccess={child.perm || ''}>
                  <ItemSubMenu menu={child} />
                </Can>
              ) : (
                <ItemSubMenu key={child.name} menu={child} />
              );
            })}
          </SubMenu>
        )}
      </Item>
    </>
  );
};

export default ItemMenu;
