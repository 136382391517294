import React, { useState, useEffect, useCallback } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import { MdSearch } from 'react-icons/md';

import api from 'services/api';
import { colorCharts } from 'styles/colors';
import { randomColor } from 'utils/randomColor';
import { toFormatDate, CustomFormats } from 'utils/dateHelpers';
import { useToast } from 'contexts';

import * as C from 'components';

import Pagination from './Pagination';

import * as S from './styles';

interface IEmbeddedQtd {
  descricao: string;
  quantidade: string;
}

interface IChartPieProps {
  name: string;
  value: number;
  key: string;
  percent?: number;
  color?: string;
}

interface IEmbeddedTable {
  data: string;
  usuario: string;
  relatorio: string;
  dataFormatada: string | null;
}

interface IPaginate {
  data: IEmbeddedTable[];
  page: number;
  per_page: number;
  last_page: number;
  total: number;
}

interface IFormFilter {
  data_inicial: Date;
  data_final: Date;
}

const Dashboard: React.FC = () => {
  const currentDate = new Date();

  const data_inicial = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1,
  );

  const data_final = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0,
  );

  const { addToast } = useToast();

  const [embedded, setEmbedded] = useState<IPaginate>();
  const [reports, setReports] = useState<IChartPieProps[]>([]);
  const [users, setUsers] = useState<IChartPieProps[]>([]);
  const [mensal, setMensal] = useState<IChartPieProps[]>([]);
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState<IFormFilter>({
    data_inicial,
    data_final,
  });

  const handleSearch = useCallback(
    async ({ page, per_page }, data) => {
      try {
        setLoading(true);

        const params = {
          page,
          per_page,
        };

        const response = await api.post<IPaginate>(
          '/relatorios/embedded',
          data,
          { params },
        );

        const newData = response.data.data.map((item) => ({
          ...item,
          dataFormatada: item.data
            ? toFormatDate(new Date(item.data), CustomFormats.dd_MM_yyyy)
            : null,
        }));

        setEmbedded({ ...response.data, data: newData });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ops...',
          description: 'Ops, ocorreu um erro no processo de pesquisa',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const embeddedByReport = useCallback(async (data) => {
    const response = await api.post<IEmbeddedQtd[]>(
      '/relatorios/embedded/by-report',
      data,
    );

    setReports(
      response.data.map((item: IEmbeddedQtd, idx: number) => ({
        name: item.descricao,
        value: Number(item.quantidade),
        key: String(idx),
        color: colorCharts[idx] || randomColor(),
      })),
    );
  }, []);

  const embeddedByUser = useCallback(async (data) => {
    const response = await api.post<IEmbeddedQtd[]>(
      '/relatorios/embedded/by-user',
      data,
    );

    setUsers(
      response.data.map((item: IEmbeddedQtd, idx: number) => ({
        name: item.descricao,
        value: Number(item.quantidade),
        key: String(idx),
        color: colorCharts[idx] || randomColor(),
      })),
    );
  }, []);

  const embeddedByMes = useCallback(async (data) => {
    const response = await api.post<IEmbeddedQtd[]>(
      '/relatorios/embedded/by-mes',
      data,
    );

    setMensal(
      response.data.map((item: IEmbeddedQtd, idx: number) => ({
        name: item.descricao,
        value: Number(item.quantidade),
        key: String(idx),
        color: colorCharts[idx] || randomColor(),
      })),
    );
  }, []);

  useEffect(() => {
    const params = {
      page: 1,
      per_page: 5,
    };

    handleSearch(params, {
      data_inicial,
      data_final,
    });

    embeddedByReport({
      data_inicial,
      data_final,
    });

    embeddedByUser({
      data_inicial,
      data_final,
    });

    embeddedByMes({
      data_inicial,
      data_final,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embeddedByReport, embeddedByUser]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm<FieldValues>({
    defaultValues: {
      data_inicial: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1,
      ),
      data_final: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0,
      ),
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      try {
        setLoading(true);

        setFilters(data);

        embeddedByReport(data);

        embeddedByUser(data);

        const params = {
          page: 1,
          per_page: 5,
        };

        handleSearch(params, data);
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },

    [addToast, embeddedByReport, embeddedByUser, handleSearch],
  );

  return (
    <S.Container>
      <C.Can checkAccess="dashboard_list">
        <>
          <S.FilterContainer>
            <form onSubmit={handleSubmit(onSubmit)}>
              <S.FormGroup>
                <C.Label>Data Inicial</C.Label>
                <C.InputDatePicker
                  {...register('data_inicial')}
                  control={control}
                  errors={errors.data_inicial}
                  dateFormat="dd/MM/yyyy"
                  selected={watch('data_inicial')}
                  name="data_inicial"
                  placeholder="Data Inicial"
                />
              </S.FormGroup>

              <S.FormGroup>
                <C.Label>Data Final</C.Label>
                <C.InputDatePicker
                  {...register('data_final')}
                  control={control}
                  errors={errors.data_final}
                  dateFormat="dd/MM/yyyy"
                  selected={watch('data_final')}
                  name="data_final"
                  placeholder="Data Final"
                />
              </S.FormGroup>

              <S.ButtonContainer>
                <C.Button
                  variant="primary"
                  disabled={loading}
                  loading={loading}
                  type="submit"
                  title="Gerar relatorio"
                >
                  <MdSearch size={24} />
                </C.Button>
              </S.ButtonContainer>
            </form>
          </S.FilterContainer>

          <S.Content>
            <S.CardTable>
              <S.Panel>
                {loading ? (
                  <C.Loader
                    loading={loading}
                    align="center"
                    message="Carregando..."
                  />
                ) : (
                  <>
                    {embedded && embedded.data[0] ? (
                      <div>
                        <C.TableResponsive>
                          <C.Table>
                            <thead>
                              <tr>
                                <th className="text-left">Data</th>
                                <th className="text-left">Usuário</th>
                                <th className="text-left">Relatorios</th>
                              </tr>
                            </thead>
                            <tbody>
                              {embedded &&
                                embedded.data.map(
                                  (item: IEmbeddedTable, idx: number) => (
                                    <tr key={String(idx)}>
                                      <td>{item.dataFormatada}</td>
                                      <td>{item.usuario}</td>
                                      <td>{item.relatorio}</td>
                                    </tr>
                                  ),
                                )}
                            </tbody>
                          </C.Table>
                        </C.TableResponsive>
                        <S.FooterContainer>
                          {embedded && (
                            <Pagination
                              page={embedded?.page}
                              total={embedded?.total}
                              per_page={embedded?.per_page}
                              last_page={embedded?.last_page}
                              onSearch={handleSearch}
                              filters={filters}
                            />
                          )}
                        </S.FooterContainer>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </S.Panel>
            </S.CardTable>

            <S.CardChart1>
              <C.ChartPie title="Uso por dashboard" data={reports} size={300} />
            </S.CardChart1>

            <S.CardChart2>
              <C.ChartPie title="Uso por usuário" data={users} size={300} />
            </S.CardChart2>

            <S.CardChart3>
              <C.ChartBar title="Uso mensal" data={mensal} size={300} />
            </S.CardChart3>
          </S.Content>
        </>
      </C.Can>
    </S.Container>
  );
};

export default Dashboard;
