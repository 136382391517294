import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useLocation } from 'react-router-dom';
import { FiLock } from 'react-icons/fi';
import * as Yup from 'yup';

import { useToast } from 'contexts/ToastProvider';

import { Input, Button } from '../../components';

import logoImg from '../../assets/logo.png';
import { Container, Content, Background, AnimationContainer } from './styles';
import api from '../../services/api';

interface ResetPasswordFormData {
  password: string;
  password_confirmation: string;
}

const schema = Yup.object().shape({
  password: Yup.string().required('Senha obrigatória'),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref('password'), undefined],
    'Confirmação incorreta',
  ),
});

const ResetPassword: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormData>({
    resolver: yupResolver(schema),
  });

  const history = useHistory();
  const location = useLocation();

  const { addToast } = useToast();

  const onSubmit = useCallback(
    async (data: ResetPasswordFormData) => {
      try {
        const { password, password_confirmation } = data;
        const token = location.search.replace('?token=', '');

        if (!token) {
          throw new Error();
        }

        await api.post('/password/reset', {
          password,
          password_confirmation,
          token,
        });

        history.push('/');
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro ao resetar senha',
          description: 'Ocorreu um erro ao resetar sua senha, tente novamente',
        });
      }
    },
    [addToast, history, location],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="A1 Analytics" />
          <form onSubmit={handleSubmit(onSubmit)}>
            <h1>Resetar senha</h1>
            <Input
              {...register('password')}
              type="password"
              errors={errors.password}
              name="password"
              icon={FiLock}
              placeholder="Nova Senha"
              autoComplete="off"
            />
            <Input
              {...register('password_confirmation')}
              type="password"
              errors={errors.password_confirmation}
              name="password_confirmation"
              icon={FiLock}
              placeholder="Confirmação da senha"
              autoComplete="off"
            />

            <Button type="submit">Alterar senha</Button>
          </form>
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};

export default ResetPassword;
