import React, { createContext, useCallback, useState, useContext } from 'react';
import { isBefore } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import { singIn } from 'services/api/SignIn';
import api from 'services/api';
import { IAuthContextData, IAuthState, IUser } from './types';

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<IAuthState>(() => {
    const token = localStorage.getItem('@a1Analytics:token');
    const tokenExpires = localStorage.getItem('@a1Analytics:tokenExpires');
    const user = localStorage.getItem('@a1Analytics:user');
    const permissions = localStorage.getItem('@a1Analytics:permissions');
    const access = localStorage.getItem('@a1Analytics:access');

    if (!tokenExpires) {
      // Para evitar erros caso não tenha tokenExpires
      localStorage.removeItem('@a1Analytics:token');
      localStorage.removeItem('@a1Analytics:tokenExpires');
      localStorage.removeItem('@a1Analytics:user');
      localStorage.removeItem('@a1Analytics:permissions');
      localStorage.removeItem('@a1Analytics:access');

      return {} as IAuthState;
    }

    if (
      tokenExpires &&
      isBefore(
        zonedTimeToUtc(tokenExpires, 'America/Sao_Paulo'),
        zonedTimeToUtc(new Date(), 'America/Sao_Paulo'),
      )
    ) {
      localStorage.removeItem('@a1Analytics:token');
      localStorage.removeItem('@a1Analytics:tokenExpires');
      localStorage.removeItem('@a1Analytics:user');
      localStorage.removeItem('@a1Analytics:permissions');
      localStorage.removeItem('@a1Analytics:access');

      return {} as IAuthState;
    }

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return {
        token,
        user: JSON.parse(user),
        permissions: permissions ? JSON.parse(permissions) : [],
        access: access ? JSON.parse(access) : [],
      };
    }

    return {} as IAuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await singIn({ email, password });

    const { token, user, expires_in, permissions, access } = response;

    localStorage.setItem('@a1Analytics:token', token);
    localStorage.setItem('@a1Analytics:tokenExpires', expires_in);
    localStorage.setItem('@a1Analytics:user', JSON.stringify(user));
    localStorage.setItem(
      '@a1Analytics:permissions',
      JSON.stringify(permissions),
    );
    localStorage.setItem('@a1Analytics:access', JSON.stringify(access));

    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, user, permissions, access });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@a1Analytics:token');
    localStorage.removeItem('@a1Analytics:tokenExpires');
    localStorage.removeItem('@a1Analytics:user');
    localStorage.removeItem('@a1Analytics:permissions');
    localStorage.removeItem('@a1Analytics:access');

    setData({} as IAuthState);
  }, []);

  const updateUser = useCallback(
    (user: IUser) => {
      setData({
        ...data,
        token: data.token,
        user,
      });
      localStorage.setItem('@a1Analytics:user', JSON.stringify(user));
    },
    [setData, data],
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        permissions: data.permissions,
        access: data.access,
        signIn,
        signOut,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
