import { IPaginate, IParams } from 'shared/interfaces';

import api from 'services/api';

import { IUser, IUserForm } from './interfaces';

const createFormDataReport = (user: IUserForm) => {
  const data = {
    ...user,
    permission_id: user.permission_id?.value || null,
  };

  return data;
};

const getUser = async (id: string): Promise<IUser> => {
  const user = await api.get<IUser>(`/users/${id}`);

  const data: IUser = {
    ...user.data,
    permission_id: user.data?.permission.id
      ? {
          label: String(user.data?.permission.name) || '',
          value: user.data?.permission.id || '',
        }
      : '',
  };

  return data;
};

const getUsers = async (params: IParams): Promise<IPaginate<IUser>> => {
  const response = await api.get<IPaginate<IUser>>('/users', {
    params,
  });

  return response.data;
};

const createUser = async (user: IUserForm): Promise<void> => {
  const data = createFormDataReport(user);

  await api.post('/users', data);
};

const updateUser = async (id: string, user: IUserForm): Promise<IUser> => {
  const data = createFormDataReport(user);

  const response = await api.put<IUser>(`/users/${id}`, data);

  return response.data;
};

const deleteUser = async (id: string): Promise<void> => {
  await api.delete(`/users/${id}`);
};

export { getUser, getUsers, createUser, updateUser, deleteUser };
